<template>
  <div class="signup">
    <h1>Sign Up</h1>
    <v-container>
      <v-row justify="center">
        <div style="text-align:center">
          <p>Welcome to Budget ICU!</p>
          <p>We are currently
            in alpha, and require an application to
            grant access.
          </p>
          <p>Please tell us about yourself!</p>
          <a href="/about"><p>Alternatively, you can read more about us here</p></a>
        </div>
      </v-row>
    </v-container>
    <v-divider/>
    <v-container>
      <v-row>
      <v-col cols="2"/>
      <v-col cols="8">
        <v-progress-linear
            v-if="submitted"
            indeterminate
        />
        <v-form
            ref="signUpForm"
            :disabled="submitted"
        >
          <v-card>
            <v-card-title>Sign Up Form</v-card-title>
            <v-card-text>
              <!-- username, email, why, pain-points -->
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <h3>Login Information</h3>
                    <br>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                            :rules="usernameRules"
                            v-model="formUsername"
                            label="Username"
                            placeholder="Username"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                            :rules="emailRules"
                            v-model="formEmail"
                            label="Email"
                            placeholder="Email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Why Budget ICU?</h3>
                    <br>
                    <p>
                      There are lots of options for
                      personal finance. What brings you here?
                    </p>
                    <v-textarea
                        counter
                        :rules="textAreaRules"
                        v-model="formWhy"
                        label="Why join the alpha?"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Desired Features</h3>
                    <br>
                    <p>
                      What pain points have you had with
                      other budget or personal finance software?
                    </p>
                    <p>
                      We would love to improve your experience!
                    </p>
                    <v-textarea
                        counter
                        :rules="textAreaRules"
                        v-model="formImprove"
                        label="What can we improve?"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <a v-on:click="showTerms"><h3>Terms and Conditions</h3></a>
                    <v-checkbox
                        :rules="checkboxRules"
                        v-model="formTermsAgree"
                        label="I have read and agree to the Terms of Service"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn v-on:click="submitForm" :disabled="submitted">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-progress-linear
          v-if="submitted"
          indeterminate
        />
      </v-col>
      </v-row>
    </v-container>
    <message-dialog
        v-model="successfulDialog"
        v-on:OK="successfulDialog = false"
        title="Success!"
    >
      <p>
        Thank you for submitting your request!
      </p>
      <p>
        We will get back to you shortly!
      </p>
    </message-dialog>
    <message-dialog
        v-model="errorDialog"
        v-on:OK="errorDialog = false"
        title="Uh oh! Error!"
    >
      <p>
        Our apologies. There was an error processing your request.
      </p>
      <p>
        Please try again later!
      </p>
    </message-dialog>
    <nothing-dialog v-model="dialog" v-on:OK="dialog = false"></nothing-dialog>
  </div>
</template>

<script>
import NothingDialog from "@/components/NothingDialog";
import MessageDialog from "@/components/MessageDialog";

import {Action, Getter} from '@/store/anonymous/types.js'
import goTo from 'vuetify/es5/services/goto'

export default {
  name: "SignUp",
  metaInfo: {
    title: 'Sign Up',
  },
  components: {NothingDialog, MessageDialog},
  data: () => ({
    termsDialog: false,
    dialog: false,
    // TODO: a way to save form state locally so if they navigate away it stays...?
    formUsername: null,
    formEmail: null,
    formWhy: null,
    formImprove: null,
    formTermsAgree: false,
    textAreaRules: [
        v => !!v || 'Required',
        v => (v && v.length >= 5) || 'Minimum 5 characters',
        v => (v && v.length <= 500) || 'Max 500 characters',
    ],
    usernameRules: [
        v => !!v || 'Required',
        v => (v && v.length >= 2) || 'Minimum 2 characters',
        v => (v && v.length <= 30) || 'Max 30 characters',
    ],
    emailRules: [
        v => !!v || 'Required',
        v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || 'Invalid e-mail.'
        },
        v => (v && v.length >= 5) || 'Minimum 5 characters',
        v => (v && v.length <= 100) || 'Max 100 characters',
    ],
    checkboxRules: [
        v => v || 'Required'
    ],
    successfulDialog: false,
    errorDialog: false,
    submitted: false,
    complete: false,
  }),
  computed: {
    schema() {
      return this.$store.getters[`${[Getter.GetSignUpSchema]}`]
    },
    successful() {
      return this.$store.getters[`${[Getter.GetSuccessful]}`]
    }
  },
  watch: {
    successful: function(newVal, oldVal) {
      // when first successful
      if (this.submitted && !oldVal && newVal) {
        this.showSuccessful()
      }
    }
  },
  methods: {
    submitForm: function() {
      // ensure complete is false (i.e. previous submission)
      this.complete = false

      let status = this.$refs.signUpForm.validate()
      if (!status) {
        // TODO: handle error and make the submit button buzz or something

        // delay until classes show up
        setTimeout(() => {
          let minErrorTop = this.computeErrorTop()
          let currentOffset = window.pageYOffset
          // do not scroll down...
          let absMin = Math.min(currentOffset, currentOffset + minErrorTop - 10)
          goTo(absMin)
        }, 500)
        return
      }
      let submitData = {
        "username": this.formUsername,
        "email": this.formEmail,
        "why": this.formWhy,
        "features": this.formImprove,
        "agree": this.formTermsAgree
      }
      this.$store.dispatch(`${[Action.SaveSignUpForm]}`, submitData).catch(
          (err) => {
            console.log("Hello1")
            this.showError(err)
          }
      )
      this.submitted = true
    },
    showTerms: function() {
      // TODO: Create a terms and conditions dialog
      this.dialog = true
      //this.termsDialog = true
    },
    computeErrorTop: function() {
      // get the invalidated form entries
      let invalid = document.getElementsByClassName('error--text')
      let invalidArr = Array.from(invalid)
      let tops = invalidArr.map(
          x => {
            let rect = x.getBoundingClientRect();
            return rect.top - rect.height
          })
      let minTop = Math.min.apply(null, tops)
      return minTop
    },
    showSuccessful: function() {
      // clear status
      this.successfulDialog = true
      this.$store.dispatch(`${[Action.ClearSubmittedResponse]}`)

      // clear form
      this.$refs.signUpForm.reset()
      this.submitted = false
    },
    showError: function(err) {
      console.log(err)
      this.errorDialog = true
      this.submitted = false
    }
  },
  mounted() {
    this.$store.dispatch(`${[Action.LoadSignUpSchema]}`)
  },
  setup() {

  }
}
</script>

<style scoped>

</style>
